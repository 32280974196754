import './modernizr';
//import Vue from 'vue';

//Loads Stylecheets
window.loadStyle = function (href, pos, callback, after = true) {
    // avoid duplicates
    for (var i = 0; i < document.styleSheets.length; i++) {
        if (document.styleSheets[i].href == href) {
            return;
        }
    }
    var atf = document.styleSheets[pos];

    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = href;
    if (callback) {
        link.onload = function () {
            callback()
        }
    }
    if (after) {
        //atf.ownerNode.after(link);
        atf.ownerNode.parentNode.appendChild(link);
    } else {
        //atf.ownerNode.before(link);

        atf.ownerNode.parentNode.insertBefore(link, atf.ownerNode);
    }
}
//Loads JS
window.loadJS = function (src, pos, async, callback, after = true, delay = false) {
    var js = document.getElementsByTagName("script");

    // avoid duplicates
    for (var i = 0; i < js.length; i++) {
        if (js[0].hasOwnProperty('href')) {
            if (js[i].href == href) {
                return;
            }
        }
    }

    var reference = js[pos];
    console.dir(reference);
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = src;
    if (async) s.async = true;
    if (callback) {
        s.onload = function () {
            callback()
        }
    }
    if (after) {
        //reference.after(s);
        reference.appendChild(s);
    } else {
        //reference.before(s);
        reference.insertBefore(s, reference.ownerNode);
    }
}

var tracking = function () {
    (function (e, t, n, i, s, a, c) {
        e[n] = e[n] || function () {
            (e[n].q = e[n].q || []).push(arguments)
        };
        a = t.createElement(i);
        c = t.getElementsByTagName(i)[0];
        a.async = true;
        a.src = s;
        c.parentNode.insertBefore(a, c)
    })(window, document, "galite", "script", "https://cdn.jsdelivr.net/npm/ga-lite@2/dist/ga-lite.min.js");
    galite('create', 'UA-7076862-1', 'auto');
    galite('send', 'pageview');
    //window.loadJS('//js.hs-scripts.com/5855018.js', 0, true, false, true);
}
var _linkedin_partner_id;
(function () {
    if (theme_url != 'https://ate2.developerb.in/themes/ate') {
        _linkedin_partner_id = "1794449";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        tracking();
    }

    // new Vue({
    //     el: '#vue-app'
    // });
})();
